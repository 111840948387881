import Card from "components/Card"
import Content from "components/Content"
import { Outlet } from "react-router-dom"

import Button from "components/Button"
import useFetch from "hooks/useFetch"
import useUser from "hooks/useUser"
import { ReactComponent as SpinnerIcon } from "../../icons/Spinner.svg"
import MenuOffers from "./components/MenuOffers"
import useExportOffers from "./hooks/useExportOffers"

const Offers = () => {
  const {
    data: { can },
  } = useUser()
  const { loading, fetch } = useFetch("apiv1/refresh-offers")
  const { loadingImport, exportOffers, importOffers } = useExportOffers()

  return (
    <Content title="Ofertas">
      <div className="mb-3 flex gap-3">
        {can.manageUsers && (
          <Button
            className="inline-flex items-center"
            primary
            medium
            onClick={importOffers}
            disabled={loadingImport}
          >
            {loadingImport && (
              <SpinnerIcon className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
            )}
            Importar excel
          </Button>
        )}
        {can.manageUsers && (
          <Button
            className="inline-flex items-center"
            primary
            medium
            onClick={exportOffers}
          >
            Exportar excel
          </Button>
        )}
        <Button
          className="inline-flex items-center"
          primary
          medium
          onClick={fetch}
          disabled={loading}
        >
          {loading && (
            <SpinnerIcon className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
          )}
          Atualizar ofertas
        </Button>
      </div>

      <div className="flex">
        <div className="lg:w-1/3 md:w-1/2 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
          <Card title="Pacotes">
            <MenuOffers />
          </Card>
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <Outlet />
        </div>
      </div>

      <div className="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16" />
    </Content>
  )
}

export default Offers
