import { ReactComponent as EmptyBoxIcon } from "./icons/EmptyBoxIcon.svg"

const OffersEmptyState = () => {
  return (
    <div className="w-full h-full grid place-items-center p-2">
      <div className="text-center place-items-center grid border border-gray-400 p-12 rounded-xl border-dashed">
        <EmptyBoxIcon className="w-24" />
        <h1 className="mt-10 text-gray-700 font-semibold text-2xl">
          Por favor Selecione uma oferta
        </h1>
      </div>
    </div>
  )
}

export default OffersEmptyState
