import useEnv from "hooks/useEnv"
import PostsGea from "./components/PostsGea"
import PostsGecontur from "./components/PostsGecontur"

function Post() {
  const { isGea } = useEnv()

  if (isGea) {
    return <PostsGea />
  }

  return <PostsGecontur />
}

export default Post
