import useOffer from "hooks/useOffer"
import { toPng } from "html-to-image"
import type React from "react"
import { useRef, useState } from "react"
import { useParams } from "react-router-dom"

import Button from "components/Button"
import MenuRadio from "components/MenuRadio"
import useEnv from "hooks/useEnv"
import useUser from "hooks/useUser"

import styles from "./PostsGea.module.css"

enum Size {
  FB = "fb",
  Insta = "insta",
}

enum LogoSide {
  Left = "left",
  Right = "right",
}

enum PriceSide {
  Left = "left",
  Right = "right",
}

enum TitleColor {
  Gray = "#575756",
  White = "white",
}

const variants: { [key in Size]: string } = {
  [Size.FB]: styles.Facebook,
  [Size.Insta]: styles.Instagram,
}

const PostsGea: React.FC = () => {
  const { offerId } = useParams()
  const { isLoading, offer } = useOffer(offerId)
  const domEl = useRef<HTMLDivElement | null>(null)
  const [size, setSize] = useState<Size>(Size.FB)
  const [logoSide, setLogoSide] = useState<LogoSide>(LogoSide.Right)
  const [priceSide, setPriceSide] = useState<PriceSide>(PriceSide.Left)
  const [titleColor, setTitleColor] = useState<TitleColor>(TitleColor.White)

  const { geconturUrl } = useEnv()
  const { data } = useUser()

  const sizeOptions = [
    { label: "Post", value: Size.FB },
    { label: "Story", value: Size.Insta },
  ]
  const titleOptions = [
    { label: "Cinza", value: TitleColor.Gray },
    { label: "Branco", value: TitleColor.White },
  ]
  const logoSideOptions = [
    { label: "Logo - Esquerda", value: LogoSide.Left },
    { label: "Logo - Direita", value: LogoSide.Right },
  ]
  const priceSideOptions = [
    { label: "Preço - Esquerda", value: PriceSide.Left },
    { label: "Preço - Direita", value: PriceSide.Right },
  ]

  const downloadImage = async () => {
    if (domEl.current) {
      try {
        const dataUrl = await toPng(domEl.current)

        // download image
        const link = document.createElement("a")
        link.download = `oferta-${offerId}.png`
        link.href = dataUrl
        link.click()
      } catch (error) {
        console.error("Error generating image:", error)
      }
    }
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!offer) {
    return <div>No offer found.</div>
  }

  const {
    AccommodationName,
    OfferTitle,
    LeadInPrice,
    PriceType,
    ImageURL,
    Nights,
  } = offer

  const color = data?.agency.color ?? "#4fab67"
  const logo = `${geconturUrl}/img/agencies/${data?.agency?.logo_path}`

  return (
    <main className={`px-4 ${variants[size]}`}>
      <div className="mx-auto grid justify-center px-4">
        <div className={styles.FrameWrapper}>
          <div className={styles.Frame}>
            <div ref={domEl}>
              <div className={`${styles.Root}`}>
                <img
                  className={
                    logoSide === LogoSide.Left
                      ? styles.LeftLogo
                      : styles.RightLogo
                  }
                  alt={OfferTitle}
                  src={logo}
                />
                <img
                  className={styles.CoverImage}
                  alt={OfferTitle}
                  src={ImageURL}
                />

                <div
                  className={
                    priceSide === PriceSide.Left
                      ? styles.LeftContent
                      : styles.RightContent
                  }
                >
                  <div
                    className={styles.Title}
                    style={{ background: color, color: titleColor }}
                    contentEditable="true"
                  >
                    {OfferTitle}
                  </div>
                  <div className={styles.Body}>
                    <div
                      className={styles.AccommodationName}
                      contentEditable="true"
                    >
                      {AccommodationName}
                    </div>

                    <div className={styles.Nights} contentEditable="true">
                      {Nights && `${Nights} noites`}
                    </div>

                    <div className="flex justify-left gap-x-2 mt-6 mb-12 pt-2">
                      <div className="flex-60 w-1/8">
                        <div className="ComponentToPrintGea_Desde__c2MKi">
                          {PriceType}
                        </div>
                        <div
                          className="ComponentToPrintGea_Moeda__c18Xu"
                          style={{ color }}
                        >
                          €
                        </div>
                      </div>
                      <div className="flex-40 w-7/8">
                        <div
                          className="ComponentToPrintGea_Price__8AAgY"
                          style={{ color }}
                        >
                          {LeadInPrice}
                        </div>
                      </div>
                    </div>

                    <div className={styles.RNAVT}>
                      RNAVT {data?.agency?.RNAVT}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-3/4 my-1 text-center flex flex-col gap-2 p-2 mx-auto">
        <MenuRadio selected={size} options={sizeOptions} onClick={setSize} />
        <MenuRadio
          selected={logoSide}
          options={logoSideOptions}
          onClick={setLogoSide}
        />
        <MenuRadio
          selected={priceSide}
          options={priceSideOptions}
          onClick={setPriceSide}
        />
        <MenuRadio
          selected={titleColor}
          options={titleOptions}
          onClick={setTitleColor}
        />
      </div>

      <div className="my-4 flex justify-center">
        <Button primary medium onClick={downloadImage}>
          Download Imagem
        </Button>
      </div>
    </main>
  )
}

export default PostsGea
