import { useMemo } from "react"

interface EnvVariables {
  geconturUrl: string | undefined
  apiUrl: string | undefined
  theme: string | undefined
  logo: string | undefined
  company: string | undefined
  isGea: boolean
  isGecontur: boolean
}

const useEnv = (): EnvVariables => {
  return useMemo(
    () => ({
      geconturUrl: process.env.REACT_APP_GECONTUR_URL,
      apiUrl: process.env.REACT_APP_API,
      theme: process.env.REACT_APP_THEME,
      logo: process.env.REACT_APP_LOGO,
      company: process.env.REACT_APP_COMPANY,
      isGea: process.env.REACT_APP_THEME === "gea",
      isGecontur: process.env.REACT_APP_THEME === "gecontur",
    }),
    [],
  )
}

export default useEnv
