import type React from "react"
import { Link } from "react-router-dom"
import styles from "./MenuItem.module.css"

interface MenuItemProps {
  icon: string
  label: string
  route: string
  external?: boolean
}

const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  label,
  route,
  external = false,
}) => {
  if (external) {
    return (
      <a className={styles.Root} href={route}>
        <i className={`${styles.Icon} ${icon}`} />
        <span className={styles.Label}>{label}</span>
      </a>
    )
  }

  return (
    <Link className={styles.Root} to={route}>
      <i className={`${styles.Icon} ${icon}`} />
      <span className={styles.Label}>{label}</span>
    </Link>
  )
}

export default MenuItem
