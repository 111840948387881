import React from "react"

interface Option<T> {
  label: string
  value: T
}

interface MenuRadioProps<T> {
  selected: T
  options: Option<T>[]
  onClick: (value: T) => void
}

const MenuRadio = <T,>({ selected, options, onClick }: MenuRadioProps<T>) => {
  return (
    <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-full border border-gray-200 sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
      {options.map(({ label, value }) => {
        const isSelected = value === selected
        return (
          <li
            key={value as string}
            className={`w-full rounded-full text-center ${
              isSelected ? "bg-blue-500" : ""
            }`}
            onClick={() => onClick(value)}
          >
            <div className="flex items-center">
              <label
                htmlFor="horizontal-list-radio-license"
                className="py-3 w-full text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
              >
                {label}
              </label>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

export default MenuRadio
