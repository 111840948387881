import useAPI from "./useAPI"

export interface User {
  id: number
  username: string
  first_name: string
  last_name: string
  auth_key: string
  password_hash: string
  password_reset_token: string | null
  email: string
  status: number
  agency_id: number
  created_at: number
  updated_at: number
  is_online: number
  sidebar_left_visible: number
  sidebar_right_visible: number
  verification_token: string | null
  type: number
  moderate_posts: string | null
  avatar: string | null
  Subdominio: string | null
  daily_newsletter_enabled: number
  has_llego: string | null
  user_llego: string | null
  pass_llego: string | null
}

export interface Agency {
  id: number
  name: string
  district: string
  RNAVT: string
  nif: string
  address: string
  locale: string
  postal_code: string
  phone_number: number
  status: number
  email: string
  subdomain: string
  bd_egoi: string
  social_denomination: string
  facebook_url: string
  subdomain_qrcode_path: string | null
  facebook_qrcode_path: string | null
  logo_path: string
  instagram_url: string
  agency_id: number | null
  accession_date: string | null
  representative: string
  is_master: number
  instagram_qrcode_path: string | null
  color: string
}

interface UserResponse {
  user: User
  agency: Agency
  apiKey: string
  company: string
  can: Record<string, boolean>
}

const useUser = () => {
  return useAPI<UserResponse>("user", "apiv1/user")
}

export default useUser
