import useOffers from "./useOffers"

// Define the type for the offerId parameter
const useOffer = (offerId?: string) => {
  const { isLoading, data } = useOffers()

  // Return the offer by matching the CustomOfferID
  return {
    isLoading,
    offer:
      data?.offers?.find(
        (o) =>
          offerId !== undefined &&
          Number.parseInt(o.CustomOfferID) === Number.parseInt(offerId),
      ) || null,
  }
}

export default useOffer
