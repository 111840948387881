import useLeftMenu from "hooks/useLeftMenu"
import type React from "react"
import MenuItem from "./MenuItem"
import styles from "./Sidebar.module.css"

interface MenuItemType {
  label: string
  route: string
  icon: string
  external: boolean
}

const Sidebar: React.FC = () => {
  const menus: MenuItemType[] = useLeftMenu()

  return (
    <div className={styles.Root}>
      {menus.map((menu) => (
        <MenuItem
          key={menu.label}
          route={menu.route}
          icon={menu.icon}
          label={menu.label}
          external={menu.external}
        />
      ))}
    </div>
  )
}

export default Sidebar
