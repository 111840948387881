import { genRandomString } from "helpers/random"
import type React from "react"
import { type ReactNode, useCallback, useMemo, useState } from "react"
import ToastContainer from "./ToastContainer"
import ToastContext from "./ToastContext"

interface Toast {
  id: string
  type: "success" | "error" | "warning"
  message: string
}

interface ToastProviderProps {
  children: ReactNode
}

const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([])

  const deleteToast = useCallback((id: string) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id))
  }, [])

  const addToast = useCallback(
    (type: "success" | "error" | "warning", message: string) => {
      const id = genRandomString(10)
      setToasts((prevToasts) => [...prevToasts, { id, type, message }])

      setTimeout(() => {
        deleteToast(id)
      }, 4500)
    },
    [deleteToast],
  )

  const toastApi = useMemo(
    () => ({
      toast: addToast,
    }),
    [addToast],
  )

  return (
    <ToastContext.Provider value={toastApi}>
      {children}
      <ToastContainer toasts={toasts} deleteToast={deleteToast} />
    </ToastContext.Provider>
  )
}

export default ToastProvider
