import Button from "components/Button"
import Content from "components/Content"
import Loading from "components/Loading"
import MenuRadio from "components/MenuRadio"
import { saveAs } from "file-saver"
import useEnv from "hooks/useEnv"
import useUser from "hooks/useUser"
import { useRef, useState } from "react"
import WithPhoto from "./templates/WithPhoto"

import signaturePlaceholder from "assets/images/signaturePlaceholder.jpg"

function transformUser(user, agency, geconturUrl) {
  return {
    name: `${user.first_name} ${user.last_name}`,
    agency: `${agency.social_denomination} | RNAVT ${agency.RNAVT}`,
    role: "Cargo",
    phoneNumber1: agency.phone_number,
    phoneNumber2: "210 196 239",
    email: user.email,
    website: `${agency.subdomain}.bestravel.pt`,
    address: `${agency.address}\n${agency.postal_code} ${agency.locale}`,
    facebook: agency.facebook_url,
    instagram: agency.instagram_url,
    linkedin: "https://www.linkedin.com/company/bestravel/",
    documents: "https://bestravel.pt/",
    photo: user?.avatar
      ? `${geconturUrl}/img/user-images/${user.avatar}`
      : signaturePlaceholder,
  }
}

const sizeOptions = [
  { label: "Com Foto", value: true },
  { label: "Sem Foto", value: false },
]

const Signature = () => {
  const { geconturUrl } = useEnv()
  const signatureRef = useRef()
  const { isLoading, data } = useUser()
  const [withPhoto, setWithPhoto] = useState(false)
  /*
  const { register, handleSubmit, getValues, watch, formState: { errors } } = useForm({
    defaultValues: {
      name: data?.name
    },
  });
  const onSubmit = data => console.log(data);
  const name = watch("name", ""); // you can supply default value as second argument
  const email = watch("email", ""); // you can supply default value as second argument
*/

  const copySignature = () => {
    const element = signatureRef.current
    const clonedElement = element.cloneNode(true)

    // Remove contentEditable attribute from all elements within the cloned element
    const elements = clonedElement.querySelectorAll("[contenteditable]")

    for (let i = 0; i < elements.length; i++) {
      elements[i].removeAttribute("contenteditable")
    }

    // Get the HTML code of the modified element
    const html = clonedElement.innerHTML

    //const html = signatureRef.current.innerHTML
    const modifiedHTML = html.replace(/contentEditable="[^"]*"/g, "")

    const blob = new Blob([modifiedHTML], {
      type: "text/plain;charset=utf-8",
    })
    saveAs(blob, "assinatura.htm")
  }
  /*
  console.log(name, email)

  const user = {
    ...data?.user,
    name
  }
  <form onSubmit={handleSubmit(onSubmit)}>
        <input defaultValue="test" {...register("name")} />
        <input {...register("email", { required: true })} />
        {errors.exampleRequired && <span>This field is required</span>}
        
        <input type="submit" />
      </form>
  */
  return (
    <Content title="Assinatura">
      <div className="w-full h-full grid place-items-center p-2">
        {isLoading && <Loading className="mt-40" />}
        {!isLoading && (
          <div className="text-center place-items-center grid p-12 rounded-xl">
            <div ref={signatureRef}>
              <WithPhoto
                user={transformUser(data.user, data.agency, geconturUrl)}
                withPhoto={withPhoto}
              />
            </div>
            <p style={{ marginTop: "15px" }}>
              Adicione ou altere a foto de perfil na{" "}
              <a
                style={{ color: "#3c8dbc", fontWeight: "bold" }}
                href={`${geconturUrl}`}
              >
                Home
              </a>
            </p>
            <div className="w-3/4 my-1 text-center flex flex-col gap-2 p-6 mx-auto">
              <MenuRadio
                selected={withPhoto}
                options={sizeOptions}
                onClick={setWithPhoto}
              />
            </div>
            <Button className="mt-4" primary medium onClick={copySignature}>
              Download
            </Button>
          </div>
        )}
      </div>
    </Content>
  )
}

export default Signature
