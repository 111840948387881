import type React from "react"
import Toast from "./Toast"

interface ToastContainerProps {
  toasts: {
    id: string
    type: "success" | "error" | "warning"
    message: string
  }[]
  deleteToast: (id: string) => void
}

const ToastContainer: React.FC<ToastContainerProps> = ({
  toasts,
  deleteToast,
}) => {
  return (
    <div className="fixed bottom-10 w-full z-50">
      <div className="max-w-xl mx-auto">
        {toasts?.map((toast) => (
          <Toast
            id={toast.id}
            key={toast.id}
            type={toast.type}
            message={toast.message}
            deleteToast={deleteToast}
          />
        ))}
      </div>
    </div>
  )
}

export default ToastContainer
