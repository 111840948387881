import ToastContext, {
  type ToastContextType,
} from "components/toast/ToastContext"
import { useContext } from "react"

const useToast = (): ToastContextType => {
  const context = useContext(ToastContext)

  if (context === undefined) {
    throw new Error("useToast hook must be used within a ToastProvider")
  }

  return context
}

export default useToast
