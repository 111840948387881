import clsx from "clsx"
import React, { forwardRef, type ButtonHTMLAttributes } from "react"

const classes = {
  base: "focus:outline-none transition ease-in-out duration-300 rounded",
  disabled: "opacity-50 cursor-not-allowed",
  pill: "rounded-full",
  small: "px-2 py-1 text-sm",
  medium: "px-4 py-2",
  large: "px-8 py-3 text-lg",
  primary:
    "bg-blue-500 hover:bg-blue-800 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 text-white",
  secondary:
    "bg-gray-200 hover:bg-gray-800 focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 text-gray-900 hover:text-white",
  danger:
    "bg-red-500 hover:bg-red-800 focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 text-white",
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  small?: boolean
  medium?: boolean
  large?: boolean
  primary?: boolean
  secondary?: boolean
  danger?: boolean
  disabled?: boolean
  className?: string
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      disabled,
      small,
      medium,
      large,
      primary,
      secondary,
      danger,
      type = "button",
      ...props
    },
    ref,
  ) => {
    const btnClassNames = clsx(classes.base, className, {
      [classes.disabled]: disabled,
      [classes.small]: small,
      [classes.medium]: medium,
      [classes.large]: large,
      [classes.primary]: primary,
      [classes.secondary]: secondary,
      [classes.danger]: danger,
    })

    return (
      <button
        ref={ref}
        disabled={disabled}
        type={type}
        className={btnClassNames}
        {...props}
      >
        {children}
      </button>
    )
  },
)

export default Button
