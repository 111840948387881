import useEnv from "hooks/useEnv"
import useToast from "hooks/useToast"
import useUpload from "hooks/useUpload"
import useUser from "hooks/useUser"
import { useState } from "react"

const useExportOffers = () => {
  const { apiUrl } = useEnv()
  const { data } = useUser()
  const { toast } = useToast()
  const [loadingImport, setLoading] = useState(false)

  const addPdfNode = async (file) => {
    const fd = new FormData()
    fd.append("file", file)

    try {
      setLoading(true)

      const res = await fetch(
        `${apiUrl}api/import-offers?access-token=${data.apiKey}`,
        {
          method: "POST",
          body: fd,
        },
      )

      const jsonRes = await res.json()

      toast("success", jsonRes.msg)
    } catch (err) {
      toast("error", err.msg)
    } finally {
      setLoading(false)
    }
  }

  const importOffers = useUpload(addPdfNode, {
    // accept: 'application/pdf',
    multiple: false,
  })

  const exportOffers = () => window.open(`${apiUrl}apiv1/export`)

  return { loadingImport, importOffers, exportOffers }
}

export default useExportOffers
