import useAPI from "./useAPI"

export interface Offer {
  id: number
  CustomOfferID: string
  Pdf: string | null
  Type: string
  Origin: string | null
  Destination: string | null
  OfferTitle: string
  AccommodationName: string
  StarRating: number | null
  BoardBasis: string | null
  OfferReference: string
  SearchType: string | null
  StartDate: string
  EndDate: string
  Category: string
  Description: string
  MoreInformation: string | null
  WhatsIncluded: string
  Nights: number
  BasedOnAdults: number | null
  BasedOnChildren: number | null
  ImageURL: string
  URL: string
  Filename: string
  UpdatedDate: string | null
  tags: string[]
  Subdominio: string | null
  LeadInPrice: string
  PriceType: string
}

const useOffers = () => {
  return useAPI<{ offers: Offer[] }>("offers", "apiv1/offers")
}

export default useOffers
