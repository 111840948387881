import { useQuery } from "react-query"
import useEnv from "./useEnv"

const useAPI = <T>(key: string, path: string) => {
  const { apiUrl } = useEnv()

  if (!apiUrl) {
    throw new Error("API URL is not defined")
  }

  const fetchAPI = async (): Promise<T> => {
    const res = await fetch(`${apiUrl}${path}`, {
      credentials: "include",
    })

    if (!res.ok) {
      throw new Error("Failed to fetch API data")
    }

    return res.json()
  }

  return useQuery<T, Error>(key, fetchAPI)
}

export default useAPI
