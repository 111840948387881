import { useState } from "react"
import useEnv from "./useEnv"
import useToast from "./useToast"

interface FetchResponse {
  success: boolean
  msg: string
  [key: string]: any
}

const useFetch = (url: string) => {
  const { apiUrl } = useEnv()
  const { toast } = useToast()

  const [data, setData] = useState<FetchResponse | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const fetchAPI = async () => {
    try {
      setLoading(true)

      const res = await fetch(`${apiUrl}${url}`, {
        credentials: "include",
      })

      const jsonRes: FetchResponse = await res.json()

      if (!jsonRes.success) {
        const errorMessage = jsonRes.msg
        throw new Error(errorMessage)
      }

      setData(jsonRes)

      toast("success", jsonRes.msg)
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError(err)
        toast("error", err.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return { data, error, loading, fetch: fetchAPI }
}

export default useFetch
