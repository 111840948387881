import Button from "components/Button"
import { useNavigate, useParams } from "react-router-dom"
import useOffer from "../../hooks/useOffer"

const OfferEmptyState = () => {
  const navigate = useNavigate()
  const { offerId } = useParams()
  const { isLoading, offer } = useOffer(offerId)

  const onGeneratePdf = () => {
    navigate(`/offers/${offerId}/pdf`)
  }
  const onPostsClick = () => {
    navigate(`/offers/${offerId}/post`)
  }

  if (isLoading) {
    return null
  }

  if (!offer) {
    return <div>Erro</div>
  }

  const {
    AccommodationName,
    OfferTitle,
    LeadInPrice,
    ImageURL,
    WhatsIncluded,
    Nights,
    StarRating,
  } = offer

  return (
    <main className="px-4">
      <div className="max-w-xl mx-auto grid grid-cols-1">
        <div className="relative p-3 col-start-1 row-start-1 flex flex-col-reverse rounded-lg bg-gradient-to-t from-black/75 via-black/0">
          <h1 className="mt-1 text-lg font-semibold text-white dark:sm:text-white">
            {AccommodationName}
          </h1>
          <p className="text-sm leading-4 font-medium text-white dark:sm:text-slate-400">
            {Nights} noites |
          </p>
        </div>
        <div className="grid gap-4 col-start-1 col-end-3 row-start-1">
          <img
            src={ImageURL}
            alt=""
            className="w-full h-64 object-cover rounded-lg col-span-full"
            loading="lazy"
          />
        </div>
        <dl className="mt-4 text-xs font-medium grid grid-flow-col items-center row-start-2">
          <dd className="flex items-center col-span-1">
            <svg
              width="24"
              height="24"
              fill="none"
              aria-hidden="true"
              className="text-indigo-600 mr-1 stroke-current dark:stroke-indigo-500"
            >
              <path
                d="m12 5 2 5h5l-4 4 2.103 5L12 16l-5.103 3L9 14l-4-4h5l2-5Z"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="mr-5 text-indigo-600">{StarRating} </span>

            <svg
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="mr-1 text-slate-400 dark:text-slate-500"
              aria-hidden="true"
            >
              <path d="M18 11.034C18 14.897 12 19 12 19s-6-4.103-6-7.966C6 7.655 8.819 5 12 5s6 2.655 6 6.034Z" />
              <path d="M14 11a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />
            </svg>
            {OfferTitle}
          </dd>
          <dd className="text-blue-600 text-lg flex items-center justify-self-end	col-span-8">
            <span>{LeadInPrice} €</span>
          </dd>
        </dl>
        <div className="mt-4 row-start-3 flex flex-wrap justify-between">
          <Button primary medium onClick={onGeneratePdf}>
            PDF Montra
          </Button>
          <Button primary medium onClick={onPostsClick}>
            Post / Story
          </Button>
        </div>
        <p className="mt-4 row-start-5 text-sm leading-6 dark:text-slate-400 bg-white px-4 py-4 border-5 rounded-lg">
          {WhatsIncluded}
        </p>
      </div>
    </main>
  )
}

export default OfferEmptyState
