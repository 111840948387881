import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"

import "./index.css"

const loadThemeStyles = async () => {
  try {
    if (process.env.REACT_APP_THEME === "gea") {
      await import("./styles/themeGea.css")
    } else if (process.env.REACT_APP_THEME === "gecontur") {
      await import("./styles/themeGecontur.css")
    }
  } catch (error) {
    console.error("Error loading theme styles:", error)
  }
}

loadThemeStyles()

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
)
