import type React from "react"
import styles from "./Content.module.css"

interface ContentProps {
  title: string
  children: React.ReactNode
}

const Content: React.FC<ContentProps> = ({ title, children }) => {
  return (
    <div className={styles.Root}>
      <div className={styles.Title}>{title}</div>
      {children}
    </div>
  )
}

export default Content
