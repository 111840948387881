import useEnv from "./useEnv"
import useUser from "./useUser"

interface MenuItem {
  label: string
  icon: string
  route: string
  external: boolean
  visible: boolean
}

const useLeftMenu = (): MenuItem[] => {
  const { data } = useUser()
  const { geconturUrl } = useEnv()

  if (!data) {
    return []
  }

  const { can } = data

  return [
    {
      label: "Home",
      icon: "fa fa-dashboard",
      route: geconturUrl || "", // Ensure route is a string
      external: true,
      visible: true,
    },
    {
      label: "Visita Agências",
      icon: "fa fa-handshake-o",
      route: `${geconturUrl}/agency-visit`,
      external: true,
      visible: can.viewMenuAgencyVisits || can.viewMenuAgencyAllVisits,
    },
    {
      label: "Cheques Viagem",
      icon: "fa fa-money",
      route: `${geconturUrl}/travelers-check`,
      external: true,
      visible: can.viewMenuTravelerChecks,
    },
    {
      label: "Contactos",
      icon: "fa fa-address-book-o",
      route: `${geconturUrl}/contact`,
      external: true,
      visible: can.viewMenuContacts,
    },
    {
      label: "Documentos",
      icon: "fa fa-files-o",
      route: `${geconturUrl}/message`,
      external: true,
      visible: can.viewMenuDocuments,
    },
    {
      label: "Popup Mensagem",
      icon: "fa fa-window-restore",
      route: `${geconturUrl}/message`,
      external: true,
      visible: can.manageMessages,
    },
    {
      label: "Inqueritos",
      icon: "fa fa-bar-chart",
      route: `${geconturUrl}/inquiry`,
      external: true,
      visible: can.manageInquiries,
    },
    {
      label: "Ofertas",
      icon: "fa fa-cubes",
      route: "/offers",
      visible: can.viewMenuPdf,
      external: false, // You can assume internal navigation here, or adjust as needed
    },
    {
      label: "Assinatura",
      icon: "fa fa-credit-card",
      route: "/signature",
      visible: can.viewMenuSignature,
      external: false,
    },
    {
      label: "Layout Home",
      icon: "fa fa-columns",
      route: `${geconturUrl}/supplier`,
      external: true,
      visible: can.viewMenuSuppliers,
    },
    {
      label: "Marketing",
      icon: "fa fa-picture-o",
      route: `${geconturUrl}/newsletter`,
      external: true,
      visible: can.viewMenuMarketing,
    },
    {
      label: "Intranet Newtour",
      icon: "fa fa-fa fa-browser",
      route: "https://intranet.newtour.pt/site/login",
      external: true,
      visible: can.menuIntranetNewtour,
    },
    {
      label: "Admin tools",
      icon: "fa fa-gears",
      route: `${geconturUrl}/users`,
      external: true,
      visible: can.viewMenuAdmin,
    },
  ]
    .filter(({ visible }) => visible)
    .map((item) => ({
      ...item,
      route: item.route || "",
    }))
}

export default useLeftMenu
