import type { FC } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { Route, Routes } from "react-router-dom"

import ProtectedRoute from "ProtectedRoute"
import ToastProvider from "components/toast/ToastProvider"
import Page404 from "features/layout/Page404"
import Offer from "features/offers/Offer"
import OfferEmptyState from "features/offers/OfferEmptyState"
import Offers from "features/offers/Offers"
import OffersEmptyState from "features/offers/OffersEmptyState"
import OffersPdf from "features/offersPdf/OffersPdf"
import Post from "features/offersPost/Post"
import Signature from "features/signature/Signature"
import Layout from "./features/layout/Layout"

const queryClient = new QueryClient()

const App: FC = () => {
  return (
    <div className="App">
      <ToastProvider>
        <QueryClientProvider client={queryClient}>
          <ProtectedRoute>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route path="offers" element={<Offers />}>
                  <Route index element={<OffersEmptyState />} />
                  <Route path=":offerId" element={<Offer />}>
                    <Route index element={<OfferEmptyState />} />
                    <Route path="post" element={<Post />} />
                    <Route path="pdf" element={<OffersPdf />} />
                  </Route>
                </Route>
                <Route path="signature" element={<Signature />} />
                <Route path="*" element={<Page404 />} />
              </Route>
            </Routes>
          </ProtectedRoute>
        </QueryClientProvider>
      </ToastProvider>
    </div>
  )
}

export default App
