import useOffer from "hooks/useOffer"
import { toPng } from "html-to-image"
import { useRef, useState } from "react"
import { useParams } from "react-router-dom"

import Button from "components/Button"
import MenuRadio from "components/MenuRadio"
import useEnv from "hooks/useEnv"

import styles from "./PostsGecontur.module.css"

const variants = {
  transparent: styles.Transparent,
  blue: styles.Blue,
  yellow: styles.Yellow,
  top: styles.Top,
  center: styles.Center,
  fb: styles.Facebook,
  insta: styles.Instagram,
  subOpaque: styles.SubOpaque,
  subFullTransparent: styles.SubFullTransparent,
  subTransparent: styles.SubTransparent,
}
function PostsGecontur() {
  const { offerId } = useParams()
  const { isLoading, offer } = useOffer(offerId)
  const domEl = useRef(null)
  const [size, setSize] = useState("fb")
  const [position, setPosition] = useState("top")
  const [color, setColor] = useState("transparent")
  const [subtitle, setSubtitle] = useState("subFullTransparent")
  const [logoColor, setLogoColor] = useState("logoWhite")
  const { apiUrl } = useEnv()

  const logoBlue = `${apiUrl}logo-blue.png`
  const logoWhite = `${apiUrl}logo-white.png`

  const sizeOptions = [
    { label: "Post", value: "fb" },
    { label: "Story", value: "insta" },
  ]
  const colorOptions = [
    { label: "Título Sem Fundo", value: "transparent" },
    { label: "Azul", value: "blue" },
    { label: "Amarelo", value: "yellow" },
  ]
  const positionOptions = [
    { label: "Topo", value: "top" },
    { label: "Centro", value: "center" },
  ]
  const subtitleOptions = [
    { label: "Subtítulo Sem Fundo", value: "subFullTransparent" },
    { label: "Branco Transparente", value: "subTransparent" },
    { label: "Branco", value: "subOpaque" },
  ]
  const logoOptions = [
    { label: "Logo Branco", value: "logoWhite" },
    { label: "Logo Azul", value: "logoBlue" },
  ]

  const downloadImage = async () => {
    const dataUrl = await toPng(domEl.current)

    // download image
    const link = document.createElement("a")
    link.download = `oferta-${offerId}.png`
    link.href = dataUrl
    link.click()
  }

  const logo = logoColor === "logoWhite" ? logoWhite : logoBlue

  if (isLoading || !offer) {
    return null
  }

  const {
    AccommodationName,
    OfferTitle,
    LeadInPrice,
    PriceType,
    ImageURL,
    Nights,
  } = offer

  return (
    <main className={`px-4 ${variants[size]}`}>
      <div className="mx-auto grid justify-center px-4">
        <div className={styles.FrameWrapper}>
          <div className={styles.Frame}>
            <div ref={domEl}>
              <div className={`${styles.Root}`}>
                <img
                  className={styles.CoverImage}
                  alt={OfferTitle}
                  src={ImageURL}
                />
                <div
                  className={`${variants[color]} ${variants[position]} ${variants[subtitle]}`}
                >
                  <div className="text-center">
                    <div className={styles.Title} contentEditable="true">
                      {OfferTitle}
                    </div>
                    <div className={styles.Subtitle} contentEditable="true">
                      {Nights && `${Nights} noites no`}{" "}
                      <b contentEditable="true">{AccommodationName}</b>{" "}
                      {PriceType} {LeadInPrice}€
                    </div>
                  </div>
                </div>
                <img alt={OfferTitle} className={styles.Logo} src={logo} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-3/4 my-1 text-center flex flex-col gap-2 p-2 mx-auto">
        <MenuRadio selected={size} options={sizeOptions} onClick={setSize} />
        <MenuRadio
          selected={position}
          options={positionOptions}
          onClick={setPosition}
        />
        <MenuRadio selected={color} options={colorOptions} onClick={setColor} />
        <MenuRadio
          selected={subtitle}
          options={subtitleOptions}
          onClick={setSubtitle}
        />
        <MenuRadio
          selected={logoColor}
          options={logoOptions}
          onClick={setLogoColor}
        />
      </div>

      <div className="my-4 flex justify-center">
        <Button primary medium onClick={downloadImage}>
          Download Imagem
        </Button>
      </div>
    </main>
  )
}

export default PostsGecontur
