interface UploadOptions {
  accept?: string
  multiple?: boolean
}

const useUpload = (
  onUpload: (files: File | FileList) => void,
  { accept = "", multiple = false }: UploadOptions = {},
) => {
  const onChange = ({ target }: Event) => {
    const input = target as HTMLInputElement
    const { files } = input

    if (!files) return

    input.removeEventListener("change", onChange)
    input.remove()

    onUpload(multiple ? files : files[0])
  }

  const uploadFile = () => {
    const inputEL = document.createElement("input")

    inputEL.type = "file"
    inputEL.accept = accept
    inputEL.multiple = multiple

    inputEL.addEventListener("change", onChange as EventListener) // Cast to EventListener
    inputEL.click()
  }

  return uploadFile
}

export default useUpload
