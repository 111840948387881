import type React from "react"
import useEnv from "../../../hooks/useEnv"

import type { Agency } from "hooks/useUser"
import styles from "./Footer.module.css"

interface CompanyQRCodeProps {
  className: string
  domain: string
  qrcode: string
}

const CompanyQRCode: React.FC<CompanyQRCodeProps> = ({
  className,
  domain,
  qrcode,
}) => (
  <div className={className}>
    <span className={styles.AgencyLinks}>{domain}</span>
    <img alt={domain} className="mt-4" width="80" src={qrcode} />
  </div>
)

interface FooterProps {
  agency: Agency
}

const Footer: React.FC<FooterProps> = ({ agency }) => {
  const { geconturUrl } = useEnv()

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-5">
        <img
          alt="logo"
          width="210"
          src={`${geconturUrl}/img/agencies/${agency?.logo_path}`}
        />
        <div className={styles.Address}>
          {`${agency?.social_denomination} - ${agency?.address} - ${agency?.postal_code} ${agency?.locale}`}
          <br />
          Telefone*: {agency?.phone_number} - Email: {agency?.email} - NIF:{" "}
          {agency?.nif} | RNAVT {agency?.RNAVT}
        </div>
        <div className={styles.PhoneCallMsg}>
          *Custo de chamada local para a rede fixa
        </div>
      </div>
      <div className="col-span-7">
        <div className="grid grid-cols-8">
          <CompanyQRCode
            className="col-span-2 flex flex-col items-center"
            domain={"Instagram"}
            qrcode={`${geconturUrl}/img/agencies/${agency?.instagram_qrcode_path}`}
          />
          <CompanyQRCode
            className="col-span-2 flex flex-col items-center"
            domain={"Facebook"}
            qrcode={`${geconturUrl}/img/agencies/${agency?.facebook_qrcode_path}`}
          />
          <CompanyQRCode
            className="col-span-2 flex flex-col items-center"
            domain={"Site"}
            qrcode={`${geconturUrl}/img/agencies/${agency?.subdomain_qrcode_path}`}
          />
          <div className="col-span-2 flex flex-col items-center justify-center pt-3.5">
            <img
              alt="Escolha do Consumidor"
              src="/imgs/escolha-consumidor-2025.png"
              style={{ maxHeight: "115px" }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
