import type React from "react"
import { useRef } from "react"
import { isFirefox } from "react-device-detect"
import { useParams } from "react-router-dom"
import { useReactToPrint } from "react-to-print"

import Button from "components/Button"
import Loading from "components/Loading"
import useEnv from "hooks/useEnv"
import useOffer from "hooks/useOffer"
import useToast from "hooks/useToast"
import useUser from "hooks/useUser"

import ComponentToPrint from "./components/ComponentToPrint"
import ComponentToPrintGea from "./components/ComponentToPrintGea"

const OffersPdf: React.FC = () => {
  const { toast } = useToast()

  const componentRef = useRef<HTMLDivElement>(null)

  const { data } = useUser()
  const { isGea, isGecontur } = useEnv()
  const { offerId } = useParams<Record<string, string | undefined>>()
  const { isLoading, offer } = useOffer(offerId)

  const print = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `post-${offerId}`,
  })

  const handlePrint = () => {
    if (isFirefox) {
      toast(
        "warning",
        'Atenção: Se a imagem não aparecer, habilite a opção "Imprimir fundos" em "Mais definições"',
      )
    }
    print()
  }

  if (isLoading) {
    return (
      <div className="flex mt-40 justify-center">
        <Loading className="" />
      </div>
    )
  }

  return (
    <>
      {offer && isGea && (
        <ComponentToPrintGea
          ref={componentRef}
          offer={offer}
          agency={data?.agency}
        />
      )}
      {offer && isGecontur && (
        <ComponentToPrint
          ref={componentRef}
          offer={offer}
          agency={data?.agency}
        />
      )}
      <div className="mt-4 flex justify-center">
        <Button primary medium onClick={handlePrint}>
          Download Pdf
        </Button>
      </div>
    </>
  )
}

export default OffersPdf
