import type React from "react"
import useEnv from "../../../hooks/useEnv"

import type { Agency } from "hooks/useUser"
import styles from "./FooterGea.module.css"

interface FooterGeaProps {
  agency: Agency
}

const FooterGea: React.FC<FooterGeaProps> = ({ agency }) => {
  const { geconturUrl } = useEnv()

  return (
    <div className="flex">
      <div className="w-1/2 pl-2">
        <img
          className={styles.Image}
          alt="logo"
          height="62"
          src={`${geconturUrl}/img/agencies/${agency?.logo_path}`}
        />
      </div>

      <div className="ml-auto">
        <div className={styles.Address}>
          {agency?.social_denomination}
          <br />
          {agency?.address}
          <br />
          {agency?.postal_code} {agency?.locale}
          <br />
          <br />
          RNAVT {agency?.RNAVT}
        </div>
      </div>
    </div>
  )
}

export default FooterGea
