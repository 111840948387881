import type React from "react"
import type { UseFormRegister } from "react-hook-form"

interface InputProps {
  name: string
  label: string
  register: UseFormRegister<any>
  [key: string]: any
}

const Input: React.FC<InputProps> = ({ name, label, register, ...rest }) => (
  <div>
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <input
      id={name}
      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      {...register(name)}
      {...rest}
    />
  </div>
)

export default Input
