import { createContext } from "react"

export interface ToastContextType {
  toast: (type: "success" | "error" | "warning", message: string) => void
}

const initialContextState: ToastContextType = {
  toast: () => {
    throw new Error(
      "You do not need to use this context by ref, please use useDialog hook",
    )
  },
}

const ToastContext = createContext<ToastContextType>(initialContextState)

export default ToastContext
