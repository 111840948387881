import useUser from "hooks/useUser"
import type React from "react"
import { Outlet } from "react-router-dom"
import Sidebar from "../../components/Sidebar"
import useEnv from "../../hooks/useEnv"

import styles from "./Layout.module.css"

const Layout: React.FC = () => {
  const { data } = useUser()
  const { geconturUrl, logo, company, isGea } = useEnv()

  return (
    <div className={styles.Root}>
      <div className={styles.Main}>
        <a href={geconturUrl}>
          <img
            style={{ width: isGea ? "110px" : "200px" }}
            className={styles.Logo}
            alt={company}
            title={company}
            src={logo}
          />
        </a>

        <a href={geconturUrl} className={styles.User}>
          <i
            style={{ fontSize: "18px" }}
            className="fa fa-user-circle-o mr-2"
          />
          <span className="hidden-xs">{data?.user?.username}</span>
        </a>
      </div>
      <div className={styles.Menu}>
        <Sidebar />
      </div>
      <div className={styles.Content}>
        <Outlet />
      </div>
    </div>
  )
}

export default Layout
